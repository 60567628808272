<template>
  <div class="outer-wrapper">
    <div
      id="idpfw"
      class="chart-wrapper"
      :style="{ height: '430px', width: '700px' }"
    ></div>
    <select v-model="period" class="select-period">
      <option value="year">年总量</option>
      <option value="month">月均</option>
      <option value="day">日均</option>
    </select>
  </div>
</template>
<script>
import { getExcel } from "@/libs/mixins/GetExcel.js";
/* function CounterAmount(oridata, key) {
        let arda = {};
        for (let ele of oridata) {
            if (ele[key] in arda) {
                arda[ele[key]]++;

            } else {
                arda[ele[key]] = 1;

            }
        }
        return arda;
    }
    function toT(val){
    return val.toFixed(2);

}*/
function rnd(n, m) {
  var random = Math.floor(Math.random() * (m - n + 1) + n);
  return random;
}

let BarColor = [
  "#c23531",
  "#2f4554",
  "#61a0a8",
  "#d48265",
  "#91c7ae",
  "#749f83",
  "#ca8622",
  "#bda29a",
  "#6e7074",
  "#546570",
  "#c4ccd3",
];

export default {
  name: "chartPassengerFlow",
  props: [
    "ivchoice",
    "data_name",
    "selected_list",
    "keyword",
    "title_text",
    "sub_title_text",
    "chart_draw_again",
    "esize",
    "totalData",
  ],
  data() {
    return {
      ids: this.ivchoice,
      chartData: [],
      chartKey: this.ivchoice,
      chColor: "#c23531",
      period: "day",
    };
  },
  mounted() {
    let n = rnd(0, BarColor.length - 1);
    this.chColor = BarColor[n];
  },
  mixins: [getExcel],
  watch: {
    chart_draw_again: function (val) {
      if (val > 0) {
        this.drawIt();
      }
    },
    period() {
      this.drawIt();
    },
  },
  computed: {
    lineList: function () {
      let tmp = [];
      for (let i of this.totalData) {
        if (tmp.indexOf(i.line_name) === -1) {
          tmp.push(i.line_name);
        }
      }
      tmp.sort();
      return tmp;
    },
    yearList: function () {
      let tmp = [];
      for (let i of this.totalData) {
        if (tmp.indexOf(i.year) === -1) {
          tmp.push(i.year);
        }
      }
      tmp.sort();
      tmp.push(tmp[tmp.length - 1] + 1);
      tmp.push(tmp[tmp.length - 1] + 1);
      return tmp;
    },
    ctdata() {
      let temp = this.chartData;
      temp.sort((a, b) => {
        return a.value - b.value;
      });
      return temp;
    },
    series() {
      let tmp = [];
      let ele, tmpdata, tmprunDays;
      for (let line of this.lineList) {
        ele = this.ele_fac();
        ele.name = line;
        for (let year of this.yearList) {
          tmpdata = this.totalData.find(
            (val) => val.line_name === line && val.year === year
          )
            ? this.totalData.find(
                (val) => val.line_name === line && val.year === year
              )["yearPassengerFlow"]
            : NaN;
          tmprunDays = this.totalData.find(
            (val) => val.line_name === line && val.year === year
          )
            ? this.totalData.find(
                (val) => val.line_name === line && val.year === year
              )["runDays"]
            : NaN;
          if (this.period === "year") {
            ele.data.push(tmpdata);
          } else if (this.period === "month") {
            ele.data.push(((tmpdata * 30) / tmprunDays).toFixed(2));
          } else if (this.period === "day") {
            ele.data.push((tmpdata / tmprunDays).toFixed(2));
          } else {
            return [];
          }
        }
        tmp.push(ele);
      }
      return tmp;
    },
    pureData: function () {
      let res = [];
      let tmp = [];
      let tv,tmprunDays;
      for (let ele of this.lineList) {
        for (let item of this.yearList) {
          tmp = [];
          tmp[0] = ele;
          tmp[1] = item;
          tv = this.totalData.find(
            (val) => val.line_name === ele && val.year === item
          );
          tmprunDays = this.totalData.find(
            (val) => val.line_name === ele && val.year === item
          )?this.totalData.find(
            (val) => val.line_name === ele && val.year === item
          )["runDays"]:NaN;
          if (this.period === "year") {
            tmp[2] = tv ? tv["yearPassengerFlow"]: 0;
          } else if (this.period === "month") {
            tmp[2] = (((tv ? tv["yearPassengerFlow"]: 0) * 30) / tmprunDays).toFixed(2);
          } else if (this.period === "day") {
            tmp[2] = ((tv ? tv["yearPassengerFlow"]: 0) / tmprunDays).toFixed(2);
          } else {
            tmp[2] = 0;
          }
          if(!isNaN(tmp[2])){
              res.push(tmp);
          }
          
        }
      }
      return res;
    },
    periodText:function(){
        if(this.period === 'year'){
            return '年总客流量';
        }else if(this.period === 'month'){
            return '月均客流量';
        }else if(this.period === 'day'){
            return '日军客流量';
        }else{
            return '';
        }
    }
  },
  methods: {
    ele_fac() {
      return { name: "", type: "line", barGap: 0, data: [] };
    },
    /*get_data() {
      let userToken = localStorage.getItem("current_user_token");
      this.$axios
        .post(this.$url + "/ask_for_chartdata", {
          theme: this.ivchoice,
          data_name: this.data_name,
          selected_list: this.selected_list,
          keyword: this.keyword,
          userToken: userToken
        })
        .then(response => ((this.chartData = response.data), this.drawIt()));
    },*/
    drawIt() {
      let vm = this;
      let myChart = this.$echarts.init(document.getElementById("idpfw"));
      // 绘制图表
      /*var labelOption = {
    normal: {
        show: true,
        position: 'insideBottom',
        distance: 15,
        align:  'left',
        verticalAlign: 'middle',
        rotate: 90,
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
            name: {
                textBorderColor: '#fff'
            }
        }
    }
};*/
      let colorList = [];
      vm.lineList.sort((a, b) => parseInt(a) - parseInt(b));
      for (let i of vm.lineList) {
        colorList.push(
          vm.totalData.find((val) => val.line_name === i)["line_color"]
        );
      }
      let option = {
        backgroundColor: "#b4faef",
        color: colorList,
        title: {
          text: "年度线路客流量",
          subtext: "",
          left: "center",
          top: "5%",
          textStyle: {
            color: "#888",
            verticalAlign: "top",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          x: "15%",
          y: "30%",
          x2: "18%",
          y2: "30%",
          borderWidth: 10,
        },
        legend: {
          bottom: "5%",
          data: vm.lineList,
        },
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            myToolsExcel: {
              paddingTop: "5px",
              show: true,
              top: "20px",
              title: "保存数据到EXCEL",
              icon: "image:///img/excel.png",
              onclick: function () {
                vm.excelGo(vm.pureData, ["线路", "年份", vm.periodText]);
              },
            },
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar", "stack", "tiled"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: vm.yearList,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "（万乘次）",
          },
        ],
        series: vm.series,
      };
      myChart.setOption(
        option
        /*{
                    backgroundColor: '#f7f7f7',
                    
                    title: {
                        text: vm.title_text,
                        subtext: vm.sub_title_text,
                        left: 'center',
                        top: "12%",
                        textStyle: {
                            color: '#888',
                            verticalAlign: 'top'
                        }
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    grid: {
                        x: "25%",
                        y: "25%",
                        x2: "25%",
                        y2: "25%",
                        borderWidth: 10
                    },

                    toolbox: {
                        show: true,
                        orient: 'vertical',
                        left: 'right',
                        top: 'center',
                        feature: {
                            mark: {
                                show: true
                            },
                            dataView: {
                                show: true,
                                readOnly: false
                            },
                            restore: {
                                show: true
                            },
                            saveAsImage: {
                                show: true
                            }
                        }
                    },
                    dataset: {
                        source: vm.ctdata
                    },
                    xAxis: {},
                    yAxis: {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel:{
                            rotate:30,
                        },
                    },
                    series: [{
                        name: '数量',
                        type: 'bar',
                        barWidth: "40%",
                        encode: {
                            x: 1,
                            y: 2
                        },
                        label:{
                            show:true,
                            rotate:15,
                        },
                        itemStyle: {
                            color:vm.chColor,
                            opacity: 0.8,
                            shadowBlur:1,
                            shadowColor:"#e6e6e6",
                            shadowOffsetX:2,
                            shadowOffsetY:2
                        }
                    }, ],
                    label: {
                        normal: {
                            textStyle: {
                                color: 'rgba(255, 255, 255, 0.3)'
                            }
                        }
                    },
                    labelLine: {
                        normal: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.3)'
                            },
                            smooth: 0.2,
                            length: 10,
                            length2: 20
                        }
                    },
                }*/
      );
      /**myChart.on('click', function (value) {
                    vm.$emit("clickToChange", { 'value': [value.name], 'key': vm.chartKey });

                });**/
      myChart.on("legendselectchanged", function (value) {
        vm.$emit("clickToAppend", {
          value: value.selected,
          key: vm.chartKey,
        });
      });
    },
  },
};
</script>
<style scoped>
.outer-wrapper {
  margin: 0 auto;
  position: relative;
}
.chart-wrapper {
  border: 1px solid #eeeded;
  border-radius: 3px;
  margin: 0 auto;
}
.select-period {
  position: absolute;
  top: 2px;
  right: 2px;
  height: 30px;
  font-size: 14px;
  padding: 0 14px;
  color: 999;
}
</style>