<template>
  <div id="idrtd" class="chart-wrapper" :style="{ height: '400px', width: '800px' }"></div>
</template>
<script>
import { getExcel } from "@/libs/mixins/GetExcel.js";
/* function CounterAmount(oridata, key) {
        let arda = {};
        for (let ele of oridata) {
            if (ele[key] in arda) {
                arda[ele[key]]++;

            } else {
                arda[ele[key]] = 1;

            }
        }
        return arda;
    }
function rnd(n, m) {
  var random = Math.floor(Math.random() * (m - n + 1) + n);
  return random;
}

let BarColor = [
  "#c23531",
  "#2f4554",
  "#61a0a8",
  "#d48265",
  "#91c7ae",
  "#749f83",
  "#ca8622",
  "#bda29a",
  "#6e7074",
  "#546570",
  "#c4ccd3"
];*/

export default {
  name: "chartTemp2",
  props: [
    "ivchoice",
    "totalData",
    "selected_list",
    "keyword",
    "title_text",
    "sub_title_text",
    "chart_draw_again",
    "esize"
  ],
  data() {
    return {
      /*ids: this.ivchoice,
      chartData: [],
      chartKey: this.ivchoice,
      chColor: "#c23531"*/
    };
  },
  mounted() {
    /*let n = rnd(0, BarColor.length - 1);
    this.chColor = BarColor[n];*/
    this.drawIt();
  },
  mixins: [getExcel],
  watch: {
    chart_draw_again: function() {
      if (this.chart_draw_again > 0) {
        this.drawIt();
      }
    },
    askData: function() {
      this.drawIt();
    }
  },
  computed: {
    /*ctdata() {
      let temp = this.chartData;
      temp.sort((a, b) => {
        return a.value - b.value;
      });
      return temp;
    },*/
    lineList: function() {
      let tmp = [];
      for (let i of this.totalData) {
        if (tmp.indexOf(i.line_name) === -1) {
          tmp.push(i.line_name);
        }
      }
      tmp.sort();
      return tmp;
    },
    yearList: function() {
      let tmp = [];
      for (let i of this.totalData) {
        if (tmp.indexOf(i.year) === -1) {
          tmp.push(i.year);
        }
      }
      tmp.sort();
      return tmp;
    },
    askData: function() {
      let tmp = [];
      for (let i of this.lineList) {
        tmp.push([2016, parseInt(i), 0]);
      }
      for (let i of this.totalData) {
        tmp.push([
          parseInt(i.year),
          parseInt(i.line_name),
          i.delayAmount[this.ivchoice]
        ]);
      }
      for (let i of this.lineList) {
        tmp.push([Math.max(...this.yearList) + 1, parseInt(i), 0]);
      }
      for (let i of this.lineList) {
        tmp.push([Math.max(...this.yearList) + 2, parseInt(i), 0]);
      }
      return tmp;
    },
    pureData: function () {
      let res = [];
      let tmp = [];
      let tv;
      for (let ele of this.lineList) {
        for (let item of this.yearList) {
          tmp = [];
          tmp[0] = ele;
          tmp[1] = item;
          tv = this.totalData.find(
            (val) => val.line_name === ele && val.year === item
          );
          tmp[2] = tv ? tv.delayAmount[this.ivchoice] : 0;
          res.push(tmp);
        }
      }
      return res;
    },
  },
  methods: {
    /*get_data() {
      let userToken = localStorage.getItem("current_user_token");
      this.$axios
        .post(this.$url + "/ask_for_chartdata", {
          theme: this.ivchoice,
          data_name: this.data_name,
          selected_list: this.selected_list,
          keyword: this.keyword,
          userToken: userToken
        })
        .then(response => ((this.chartData = response.data), this.drawIt()));
    },*/
    drawIt() {
      let vm = this;
      let myChart = this.$echarts.init(document.getElementById("idrtd"));
      // 绘制图表
      let dataR = [
        this.askData
      ]; /*[
        [
          [2016, 2, 0],
 [2016, 3, 0],
[2016, 4, 0],
[2017, 2, 1],
 [2017, 3, 4],
[2017, 4, 1],
[2018, 2, 0],
[2018, 3, 0],
[2018, 4, 0],
[2019, 2, 0],
[2020, 3, 0],
[2021, 4, 0],
[2019, 2, 0],
[2020, 3, 0],
[2021, 4, 0],
 [2019, 2, 0],
[2020, 3, 0],
[2021, 4, 0],
        ]
      ];*/
      const enToch = {
        cheliang: "车辆设备",
        gongwu: "工务设施设备",
        gongdian: "供电设备",
        xinhao: "信号设备",
        pingbimen: "站台门设备",
        diaodu: "调度不当",
        chengwu: "乘务操作不当",
        zhanwu: "站务操作不当",
        chengke: "乘客影响",
        other: "其它"
      };
      let option = {
        title: {
          top: "5%",
          left: "30%",
          text: enToch[vm.ivchoice] + "因素不正点列次数量分布"
        },
        toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          myToolsExcel: {
              paddingTop: "5px",
              show: true,
              top: "20px",
              title: "保存数据到EXCEL",
              icon: "image:///img/excel.png",
              onclick: function () {
                vm.excelGo(vm.pureData, ["线路", "年份",{'cheliang':'车辆','gongwu':'工务','xinhao':'信号','gongdian':'供电','pingbimen':'站台门','diaodu':'调度','chengwu':'乘务','zhanwu':'站务','chengke':'乘客','otherEquipment':'其他设备','other':'其他'}[vm.ivchoice]+"因素延误次数"]);
              },
            },
            mark: {show: true},
            dataView: {show: true, readOnly: false},
            restore: {show: true},
            saveAsImage: {show: true}
        }
    },
        legend: {
          top: "5%",
          right: "5%",
          data: [enToch[vm.ivchoice]]
        },
        grid: {
          x: "18%",
          y: "25%",
          x2: "18%",
          y2: "18%",
          borderWidth: 10
        },
        xAxis: {
          splitLine: {
            lineStyle: {
              type: "dashed"
            }
          },
          scale: true
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              type: "dashed"
            }
          },
          axisLabel: {
            formatter: function(s) {
              if (s === "0" || s === 0) {
                return "";
              } else {
                return s + "号线";
              }
            }
          }
        },
        series: [
          {
            name: enToch[vm.ivchoice],
            data: dataR[0],
            type: "scatter",
            symbolSize: function(data) {
              return data[2] * 6;
            },
            label: {
              emphasis: {
                show: true,
                formatter: function(param) {
                  return param.data[2] + "次";
                },
                position: "top"
              }
            },
            itemStyle: {
              normal: {
                shadowBlur: 10,
                shadowColor: "rgba(120, 36, 50, 0.5)",
                shadowOffsetY: 5
              }
            }
          }
        ]
      };
      myChart.setOption(
        option
        /*{
                    backgroundColor: '#f7f7f7',
                    
                    title: {
                        text: vm.title_text,
                        subtext: vm.sub_title_text,
                        left: 'center',
                        top: "12%",
                        textStyle: {
                            color: '#888',
                            verticalAlign: 'top'
                        }
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    grid: {
                        x: "25%",
                        y: "25%",
                        x2: "25%",
                        y2: "25%",
                        borderWidth: 10
                    },
                    toolbox: {
                        show: true,
                        orient: 'vertical',
                        left: 'right',
                        top: 'center',
                        feature: {
                            mark: {
                                show: true
                            },
                            dataView: {
                                show: true,
                                readOnly: false
                            },
                            restore: {
                                show: true
                            },
                            saveAsImage: {
                                show: true
                            }
                        }
                    },
                    dataset: {
                        source: vm.ctdata
                    },
                    xAxis: {},
                    yAxis: {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel:{
                            rotate:30,
                        },
                    },
                    series: [{
                        name: '数量',
                        type: 'bar',
                        barWidth: "40%",
                        encode: {
                            x: 1,
                            y: 2
                        },
                        label:{
                            show:true,
                            rotate:15,
                        },
                        itemStyle: {
                            color:vm.chColor,
                            opacity: 0.8,
                            shadowBlur:1,
                            shadowColor:"#e6e6e6",
                            shadowOffsetX:2,
                            shadowOffsetY:2
                        }
                    }, ],
                    label: {
                        normal: {
                            textStyle: {
                                color: 'rgba(255, 255, 255, 0.3)'
                            }
                        }
                    },
                    labelLine: {
                        normal: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.3)'
                            },
                            smooth: 0.2,
                            length: 10,
                            length2: 20
                        }
                    },
                }*/
      );
      /**myChart.on('click', function (value) {
                    vm.$emit("clickToChange", { 'value': [value.name], 'key': vm.chartKey });

                });
      myChart.on("legendselectchanged", function(value) {
        vm.$emit("clickToAppend", {
          value: value.selected,
          key: vm.chartKey
        });
      });**/
    }
  }
};
</script>
<style scoped>
.chart-wrapper {
  border: 1px solid #eeeded;
  border-radius: 3px;
  margin: 0 auto;
  position: relative;
}
</style>