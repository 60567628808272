<template>
  <div id="ids" class="chart-wrapper" :style="{ height: '400px', width: '800px' }"></div>
</template>
<script>
import { getExcel } from "@/libs/mixins/GetExcel.js";
/* function CounterAmount(oridata, key) {
        let arda = {};
        for (let ele of oridata) {
            if (ele[key] in arda) {
                arda[ele[key]]++;

            } else {
                arda[ele[key]] = 1;

            }
        }
        return arda;
    }*/
function rnd(n, m) {
  var random = Math.floor(Math.random() * (m - n + 1) + n);
  return random;
}

let BarColor = [
  "#c23531",
  "#2f4554",
  "#61a0a8",
  "#d48265",
  "#91c7ae",
  "#749f83",
  "#ca8622",
  "#bda29a",
  "#6e7074",
  "#546570",
  "#c4ccd3"
];

export default {
  name: "chartLineAmount",
  props: [
    "ivchoice",
    "totalData",
    "selected_list",
    "keyword",
    "title_text",
    "sub_title_text",
    "chart_draw_again",
    "esize"
  ],
  data() {
    return {
      /*ids: this.ivchoice,
      chartData: [],
      chartKey: this.ivchoice,
      chColor: "#c23531"*/
    };
  },
  mounted() {
    /*let n = rnd(0, BarColor.length - 1);
    this.chColor = BarColor[n];*/
  },
  mixins: [getExcel],
  watch: {
    chart_draw_again: function(val){
        if (val> 0) {
          this.drawIt();
        }
    },
    ivchoice:function(){
      this.drawIt();
    }
  },
  computed: {
    lineList:function(){
      let tmp = [];
      for(let i of this.totalData){
        if(tmp.indexOf(i.line_name)===-1){
          tmp.push(i.line_name);
        }
      }
      tmp.sort();
      return tmp;
    },
    yearList:function(){
      let tmp = [];
      for(let i of this.totalData){
        if(tmp.indexOf(i.year)===-1){
          tmp.push(i.year);
        }
      }
      tmp.sort();
      tmp.push(tmp[tmp.length-1]+1);
      tmp.push(tmp[tmp.length-1]+1);
      return tmp;
    },
    pureData: function () {
      let res = [];
      let tmp = [];
      let tv;
      for (let ele of this.lineList) {
        for(let item of this.yearList){
          tmp = [];
          tmp[0] = ele;
          tmp[1] = item;
          tv = this.totalData.find(val=>val.line_name===ele&&val.year===item);
          tmp[2] = tv?tv[this.ivchoice]:0;
          res.push(tmp);
        }
      }
      return res;
    },
  },
  methods: {
    /*get_data() {
      let userToken = localStorage.getItem("current_user_token");
      this.$axios
        .post(this.$url + "/ask_for_chartdata", {
          theme: this.ivchoice,
          data_name: this.data_name,
          selected_list: this.selected_list,
          keyword: this.keyword,
          userToken: userToken
        })
        .then(response => ((this.chartData = response.data), this.drawIt()));
    },*/
    drawIt() {
      let vm = this;
      let myChart = this.$echarts.init(document.getElementById("ids"));
      // 绘制图表
      var labelOption = {
        normal: {
          show: true,
          position: "insideBottom",
          distance: 15,
          align: "left",
          verticalAlign: "middle",
          rotate: 90,
          formatter: "{name|{a}}",
          fontSize: 8,
          rich: {
            name: {
              textBorderColor: "#fff"
            }
          }
        }
      };
      let ser = [];
      let serEle = {};
      let ttmp = null;
      let colorList = [];
      vm.lineList.sort((a,b)=>parseInt(a)-parseInt(b));
      for(let i of vm.lineList){
        colorList.push(vm.totalData.find(val=>val.line_name===i)['line_color']?vm.totalData.find(val=>val.line_name===i)['line_color']:BarColor[rnd(0,5)]);
        serEle = {data:[]};
        serEle.name = i;
        serEle.type ="bar";
        serEle.barGap = 0;
        serEle.label = labelOption;
        serEle.animation =true;
        for(let y of vm.yearList){
          ttmp = vm.totalData.find(val=>val.line_name===i&&val.year===y);
          if(ttmp&&ttmp[vm.ivchoice]){
            serEle.data.push(ttmp[vm.ivchoice]);
          }else{
            serEle.data.push(NaN);
          }
        }
        ser.push(JSON.parse(JSON.stringify(serEle)));
      }
      let option = {
        backgroundColor: "#fff",
        color: colorList,
        /*title: {
                text: '线路开行列次',
                subtext: '',
                left: 'center',
                top: "12%",
                textStyle: {
                    color: '#888',
                    verticalAlign: 'top'
                }
            },*/
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          x: "18%",
          y: "25%",
          x2: "18%",
          y2: "18%",
          borderWidth: 10
        },
        legend: {
            width:300,
            type:'scroll',
            top:'5%',
            right:'1%',
            x: "right",
            data: vm.lineList,//["2号线", "3号线", "4号线"]
            selected: {}
          },
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            myToolsExcel: {
              paddingTop: "5px",
              show: true,
              top: "20px",
              title: "保存数据到EXCEL",
              icon: "image:///img/excel.png",
              onclick: function () {
                vm.excelGo(vm.pureData, ["线路", '年份',"数量"]);
              },
            },
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar", "stack", "tiled"] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: vm.yearList.map(String)
          }
        ],
        yAxis: [
          {
            type: "value"
          }
        ],
        series: ser
          /*{
            name: "2号线",
            type: "bar",
            barGap: 0,
            label: labelOption,
            animation: true,
            data: vm.askData['2号线']
            //[181577, 176261, 0, 0, 0]
          },
          {
            name: "3号线",
            type: "bar",
            label: labelOption,
            animation: true,
            data: vm.askData['3号线']
            //[131510, 140517, 0, 0, 0]
          },
          {
            name: "4号线",
            type: "bar",
            label: labelOption,
            animation: true,
            data: vm.askData['4号线']
            //[153768, 156158, 0, 0, 0]
          }*/
        
      };
      myChart.setOption(
        option
        /*{
                    backgroundColor: '#f7f7f7',
                    
                    title: {
                        text: vm.title_text,
                        subtext: vm.sub_title_text,
                        left: 'center',
                        top: "12%",
                        textStyle: {
                            color: '#888',
                            verticalAlign: 'top'
                        }
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    grid: {
                        x: "25%",
                        y: "25%",
                        x2: "25%",
                        y2: "25%",
                        borderWidth: 10
                    },

                    toolbox: {
                        show: true,
                        orient: 'vertical',
                        left: 'right',
                        top: 'center',
                        feature: {
                            mark: {
                                show: true
                            },
                            dataView: {
                                show: true,
                                readOnly: false
                            },
                            restore: {
                                show: true
                            },
                            saveAsImage: {
                                show: true
                            }
                        }
                    },
                    dataset: {
                        source: vm.ctdata
                    },
                    xAxis: {},
                    yAxis: {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel:{
                            rotate:30,
                        },
                    },
                    series: [{
                        name: '数量',
                        type: 'bar',
                        barWidth: "40%",
                        encode: {
                            x: 1,
                            y: 2
                        },
                        label:{
                            show:true,
                            rotate:15,
                        },
                        itemStyle: {
                            color:vm.chColor,
                            opacity: 0.8,
                            shadowBlur:1,
                            shadowColor:"#e6e6e6",
                            shadowOffsetX:2,
                            shadowOffsetY:2
                        }
                    }, ],
                    label: {
                        normal: {
                            textStyle: {
                                color: 'rgba(255, 255, 255, 0.3)'
                            }
                        }
                    },
                    labelLine: {
                        normal: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.3)'
                            },
                            smooth: 0.2,
                            length: 10,
                            length2: 20
                        }
                    },
                }*/
      );
      /**myChart.on('click', function (value) {
                    vm.$emit("clickToChange", { 'value': [value.name], 'key': vm.chartKey });

                });
      myChart.on("legendselectchanged", function(value) {
        vm.$emit("clickToAppend", {
          value: value.selected,
          key: vm.chartKey
        });
      });**/
    }
  }
};
</script>
<style scoped>
.chart-wrapper {
  border: 1px solid #eeeded;
  border-radius: 3px;
  margin: 0 auto;
}
</style>