<template>
  <div id="idr" class="chart-wrapper" :style="{ height: '430px', width: '700px' }"></div>
</template>
<script>
import { getExcel } from "@/libs/mixins/GetExcel.js";
/* function CounterAmount(oridata, key) {
        let arda = {};
        for (let ele of oridata) {
            if (ele[key] in arda) {
                arda[ele[key]]++;

            } else {
                arda[ele[key]] = 1;

            }
        }
        return arda;
    }*/
function rnd(n, m) {
  var random = Math.floor(Math.random() * (m - n + 1) + n);
  return random;
}

let BarColor = [
  "#c23531",
  "#2f4554",
  "#61a0a8",
  "#d48265",
  "#91c7ae",
  "#749f83",
  "#ca8622",
  "#bda29a",
  "#6e7074",
  "#546570",
  "#c4ccd3"
];

export default {
  name: "chartTemp4",
  props: [
    "ivchoice",
    "data_name",
    "selected_list",
    "keyword",
    "title_text",
    "sub_title_text",
    "chart_draw_again",
    "esize",
    "totalData"
  ],
  data() {
    return {
      ids: this.ivchoice,
      chartData: [],
      chartKey: this.ivchoice,
      chColor: "#c23531",
      catagoryKeys: ["m5", "m15", "m30"],
      catagoryValues: ["5~15min", "15~30min", "30min以上"],
      catagoryColors: ["#cd9c19", "#fd2c31", "#9802c9"]
    };
  },
  mounted() {},
  mixins: [getExcel],
  watch: {
    chart_draw_again: function() {
      if (this.chart_draw_again > 0) {
        let n = rnd(0, BarColor.length - 1);
        this.chColor = BarColor[n];
        this.drawIt();
      }
    }
  },
  computed: {
    series() {
      let tmp = [];
      let tmpdata, ele;
      for (let i = 0; i < this.catagoryKeys.length; i++) {
        for (let line of this.lineList) {
          ele = this.ele_fac();
          ele.name = this.catagoryValues[i];
          ele.stack = line;
          if(i===0){
            ele.label = {
              show:true,
              formatter: line,
              position:'insideLeft',
              align:'left',
              verticalAlign:'middle',
              rotate:90,
              fontSize: 6,
              rich: {
                  name: {
                      textBorderColor: '#fff',
                  },
              }
            };
          }
          for (let year of this.yearList) {
            tmpdata = this.totalData.find(
              val => val.line_name === line && val.year === year
            );
            if (tmpdata) {
              ele.data.push(tmpdata.delayEvent[this.catagoryKeys[i]]);
            } else {
              ele.data.push(NaN);
            }
          }
          tmp.push(ele);
        }
      }
      return tmp;
    },
    ctdata() {
      let temp = this.chartData;
      temp.sort((a, b) => {
        return a.value - b.value;
      });
      return temp;
    },
    lineList: function() {
      let tmp = [];
      for (let i of this.totalData) {
        if (tmp.indexOf(i.line_name) === -1) {
          tmp.push(i.line_name);
        }
      }
      tmp.sort();
      return tmp;
    },
    yearList: function() {
      let tmp = [];
      for (let i of this.totalData) {
        if (tmp.indexOf(i.year) === -1) {
          tmp.push(i.year);
        }
      }
      tmp.sort();
      tmp.push(tmp[tmp.length - 1] + 1);
      tmp.push(tmp[tmp.length - 1] + 1);
      return tmp;
    },
    pureData: function () {
      let res = [];
      let tmp = [];
      let tv;
      for (let i = 0; i < this.catagoryKeys.length; i++) {
      for (let ele of this.lineList) {
        for (let item of this.yearList) {
          tmp = [];
          tmp[0] = ele;
          tmp[1] = item;
          tv = this.totalData.find(
            (val) => val.line_name === ele && val.year === item
          );
          tmp[2] = this.catagoryValues[i];
          tmp[3] = tv ? tv.delayEvent[this.catagoryKeys[i]] : 0;
          res.push(tmp);
        }
      }
      }
      return res;
    },
  },
  methods: {
    ele_fac() {
      return {
        name: "",
        type: "bar",
        stack: "",
        data: []
      };
    },
    /*
    get_data() {
      let userToken = localStorage.getItem("current_user_token");
      this.$axios
        .post(this.$url + "/ask_for_chartdata", {
          theme: this.ivchoice,
          data_name: this.data_name,
          selected_list: this.selected_list,
          keyword: this.keyword,
          userToken: userToken
        })
        .then(response => ((this.chartData = response.data), this.drawIt()));
    },*/
    drawIt() {
      let vm = this;
      let myChart = this.$echarts.init(document.getElementById("idr"));
      // 绘制图表
      /*var labelOption = {
    normal: {
        show: true,
        position: 'insideBottom',
        distance: 15,
        align:  'left',
        verticalAlign: 'middle',
        rotate: 90,
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
            name: {
                textBorderColor: '#fff'
            }
        }
    }
};*/
      let option = {
        backgroundColor: "#fff1fe",
        color: vm.catagoryColors,
        title: {
          text: "延误事件次数分析",
          subtext: "",
          left: "center",
          top: "12%",
          textStyle: {
            color: "#888",
            verticalAlign: "top"
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          formatter:function (params) {
            let text = '<p>'+params[0].name+'年'+'</p>';
            let tmpdata;
            for(let line of vm.lineList){
              tmpdata = vm.totalData.find(
              val => val.line === line && val.year === parseInt(params[0].name)
              );
            text += '<p> ' + line+'--';
            for(let i in vm.catagoryKeys){
               text += '('+vm.catagoryValues[i]+')：'+(tmpdata?tmpdata.delayEvent[vm.catagoryKeys[i]]:0)+'；';
            }

            text += '</p>';
            }
              return text;
          },
        },
        grid: {
          x: "15%",
          y: "30%",
          x2: "20%",
          y2: "30%",
          borderWidth: 10
        },
        legend: {
          bottom: "10%",
          data: vm.catagoryValues
        },
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            myToolsExcel: {
              paddingTop: "5px",
              show: true,
              top: "20px",
              title: "保存数据到EXCEL",
              icon: "image:///img/excel.png",
              onclick: function () {
                vm.excelGo(vm.pureData, ["线路", "年份", '延误时长',"数量"]);
              },
            },
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar", "stack", "tiled"] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            name: "年份",
            axisTick: { show: false },
            data: vm.yearList
          }
        ],
        yAxis: [
          {
            name: "次数",
            type: "value"
          }
        ],
        series: vm.series
      };
      myChart.setOption(
        option
        /*{
                    backgroundColor: '#f7f7f7',
                    
                    title: {
                        text: vm.title_text,
                        subtext: vm.sub_title_text,
                        left: 'center',
                        top: "12%",
                        textStyle: {
                            color: '#888',
                            verticalAlign: 'top'
                        }
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    grid: {
                        x: "25%",
                        y: "25%",
                        x2: "25%",
                        y2: "25%",
                        borderWidth: 10
                    },

                    toolbox: {
                        show: true,
                        orient: 'vertical',
                        left: 'right',
                        top: 'center',
                        feature: {
                            mark: {
                                show: true
                            },
                            dataView: {
                                show: true,
                                readOnly: false
                            },
                            restore: {
                                show: true
                            },
                            saveAsImage: {
                                show: true
                            }
                        }
                    },
                    dataset: {
                        source: vm.ctdata
                    },
                    xAxis: {},
                    yAxis: {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel:{
                            rotate:30,
                        },
                    },
                    series: [{
                        name: '数量',
                        type: 'bar',
                        barWidth: "40%",
                        encode: {
                            x: 1,
                            y: 2
                        },
                        label:{
                            show:true,
                            rotate:15,
                        },
                        itemStyle: {
                            color:vm.chColor,
                            opacity: 0.8,
                            shadowBlur:1,
                            shadowColor:"#e6e6e6",
                            shadowOffsetX:2,
                            shadowOffsetY:2
                        }
                    }, ],
                    label: {
                        normal: {
                            textStyle: {
                                color: 'rgba(255, 255, 255, 0.3)'
                            }
                        }
                    },
                    labelLine: {
                        normal: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.3)'
                            },
                            smooth: 0.2,
                            length: 10,
                            length2: 20
                        }
                    },
                }*/
      );
      /**myChart.on('click', function (value) {
                    vm.$emit("clickToChange", { 'value': [value.name], 'key': vm.chartKey });

                });**/
      myChart.on("legendselectchanged", function(value) {
        vm.$emit("clickToAppend", {
          value: value.selected,
          key: vm.chartKey
        });
      });
    }
  }
};
</script>
<style scoped>
.chart-wrapper {
  border: 1px solid #eeeded;
  border-radius: 3px;
  margin: 0 auto;
}
</style>