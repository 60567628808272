<template>
  <div class="outer-wrapper">
    <div class="inner-wrapper">
      <div class="data-wrapper">
        <div class="section-wrapper">
          <div class="lable-wrapper">
            <p class="section-title">
              <span class="title-box">线路列次数据汇总</span>
            </p>
          </div>
          <div class="content-wrapper">
            <div class="con">
              <div class="d img1 hov" @click="chooseTrainClass('planAmount')">
                <a>
                  <div class="imgs">
                    <img src title alt />
                  </div>
                  <p :class="{'rp':choosedAmountData==='planAmount'}">线路图定开行列次</p>
                </a>
              </div>
              <div class="d img2 hov" @click="chooseTrainClass('actualAmount')">
                <a>
                  <div class="imgs">
                    <img src title alt />
                  </div>
                  <p :class="{'rp':choosedAmountData==='actualAmount'}">线路实际开行列次</p>
                </a>
              </div>
              <div class="d img3 hov" @click="chooseTrainClass('extraAmount')">
                <a>
                  <div class="imgs">
                    <img src title alt />
                  </div>
                  <p :class="{'rp':choosedAmountData==='extraAmount'}">线路加开列次</p>
                </a>
              </div>
              <div class="d img4 hov" @click="chooseTrainClass('averageDayAmount')">
                <a>
                  <div class="imgs">
                    <img src title alt />
                  </div>
                  <p class="hei" :class="{'rp':choosedAmountData==='averageDayAmount'}">线路日均开行列次</p>
                </a>
              </div>
              <div class="d img5 hov" @click="chooseTrainClass('ontimeAmount')">
                <a>
                  <div class="imgs">
                    <img src title alt />
                  </div>
                  <p :class="{'rp':choosedAmountData==='ontimeAmount'}">线路准点列车次数</p>
                </a>
              </div>
            </div>

            <div class="chart-wrapper">
              <chart-line-amount :ivchoice="choosedAmountData" :totalData="totalData" :chart_draw_again="update_count"></chart-line-amount>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="data-wrapper">
      <div class="section-wrapper">
        <div class="lable-wrapper">
          <p class="section-title">
            <span class="title-box">延误因素分解</span>
          </p>
        </div>
        <div class="content-wrapper">
          <div class="chart-wrapper">
            <chart-temp3 v-show="vs==='t'" :chart_draw_again="update_count" :totalData="totalData" :major="major"></chart-temp3>
            <chart-temp2
              v-if="vs==='o'"
              :chart_draw_again="update_count"
              :ivchoice="choosedProData"
              :totalData="totalData"
            ></chart-temp2>
          </div>
          <div class="delay-select">
            <div class="imgA delay-img iA" @click="chooseProClass('cheliang')">
              <a>
                <div class="imgs">
                  <img title alt />
                </div>
                <p :class="{'rp':choosedProData==='cheliang'}">车辆</p>
              </a>
            </div>
            <div class="imgB delay-img iB" @click="chooseProClass('gongwu')">
              <a>
                <div class="imgs">
                  <img title alt />
                </div>
                <p :class="{'rp':choosedProData==='gongwu'}">工务</p>
              </a>
            </div>
            <div class="imgC delay-img iC" @click="chooseProClass('gongdian')">
              <a>
                <div class="imgs">
                  <img title alt />
                </div>
                <p :class="{'rp':choosedProData==='gongdian'}">供电</p>
              </a>
            </div>
            <div class="imgD delay-img iD" @click="chooseProClass('xinhao')">
              <a>
                <div class="imgs">
                  <img title alt />
                </div>
                <p :class="{'rp':choosedProData==='xinhao'}">信号</p>
              </a>
            </div>
            <div class="imgE delay-img iE" @click="chooseProClass('pingbimen')">
              <a>
                <div class="imgs">
                  <img title alt />
                </div>
                <p :class="{'rp':choosedProData==='pingbimen'}">站台门</p>
              </a>
            </div>
            <div class="imgF delay-img iF" @click="chooseProClass('diaodu')">
              <a>
                <div class="imgs">
                  <img title alt />
                </div>
                <p :class="{'rp':choosedProData==='diaodu'}">调度</p>
              </a>
            </div>
            <div class="imgG delay-img iG" @click="chooseProClass('chengwu')">
              <a>
                <div class="imgs">
                  <img title alt />
                </div>
                <p :class="{'rp':choosedProData==='chengwu'}">乘务</p>
              </a>
            </div>
            <div class="imgH delay-img iH" @click="chooseProClass('zhanwu')">
              <a>
                <div class="imgs">
                  <img title alt />
                </div>
                <p :class="{'rp':choosedProData==='zhanwu'}">站务</p>
              </a>
            </div>
            <div class="imgI delay-img iI" @click="chooseProClass('chengke')">
              <a>
                <div class="imgs">
                  <img title alt />
                </div>
                <p :class="{'rp':choosedProData==='chengke'}">乘客</p>
              </a>
            </div>
            <div class="imgJ delay-img iJ" @click="chooseProClass('other')">
              <a>
                <div class="imgs">
                  <img title alt />
                </div>
                <p :class="{'rp':choosedProData==='other'}">其它</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="data-wrapper">
      <div class="section-wrapper">
        <div class="lable-wrapper">
          <p class="section-title">
            <span class="title-box">线路客流总量分析</span>
          </p>
        </div>
        <div class="content-wrapper">
          <div class="d-ch">
            <div class="t4img"></div>
            <chart-passenger-flow :chart_draw_again="update_count" :totalData="totalData"></chart-passenger-flow>
          </div>
        </div>
      </div>
    </div>
    <div class="data-wrapper">
      <div class="section-wrapper">
        <div class="lable-wrapper">
          <p class="section-title">
            <span class="title-box">延误事件次数分析</span>
          </p>
        </div>
        <div class="content-wrapper">
          <div class="d-ch">
            <chart-delay :chart_draw_again="update_count" :totalData="totalData"></chart-delay>
            <div class="t7img"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="data-wrapper">
      <div class="section-wrapper">
        <div class="lable-wrapper">
          <p class="section-title">
            <span class="title-box">线路清客列次</span>
          </p>
        </div>
        <div class="content-wrapper">
          <div class="d-ch">
            <div class="t5img"></div>
            <chart-clear :chart_draw_again="update_count" :totalData="totalData"></chart-clear>
          </div>
        </div>
      </div>
    </div>
    <div class="data-wrapper">
      <div class="section-wrapper">
        <div class="lable-wrapper">
          <p class="section-title">
            <span class="title-box">线路列车准点率</span>
          </p>
        </div>
        <div class="content-wrapper">
          <div class="d-ch">
            <chart-ontime :chart_draw_again="update_count" :totalData="totalData"></chart-ontime>
            <div class="t6img"></div>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="data-wrapper">
      <div class="section-wrapper">
        <div class="lable-wrapper">
          <p class="section-title"><span class="title-box">其他数据</span></p>
        </div>
        <div class="content-wrapper">
            <div class="one-over">
                
                <div class="rec1 rt">
                    <chart-temp4></chart-temp4>
                </div>
                <div class="rec2 rt">
                    
                </div>
                <div class="rec3 rt">
                    <chart-temp6></chart-temp6>
                </div>
                <div class="rec4 rt">
                    <chart-temp5></chart-temp5>
                </div>
                <div class="rec5 rt"></div>
                <div class="rec6 rt"></div>
            </div>
            
            </div>
      </div>
    </div>-->
  </div>
</template>
<script>
import chartLineAmount from "@/components/Charts/ChartLineAmount.vue";
import chartTemp2 from "@/components/Charts/ChartTemp2.vue";
import chartTemp3 from "@/components/Charts/ChartTemp3.vue";
import chartDelay from "@/components/Charts/ChartDelay.vue";
import chartClear from "@/components/Charts/ChartClear.vue";
import chartOntime from "@/components/Charts/ChartOntime.vue";
import chartPassengerFlow from "@/components/Charts/ChartPassengerFlow.vue";
let userToken = localStorage.getItem("current_user_token");


/*const lineData = [
  {
    line: "2号线",
    year: "2017",
    runDays: 365,
    runMil: 701.41,
    maxInterval: 160,
    normalInterval: 300,
    planAmount: 181577,
    actualAmount: 181626,
    extraAmount: 49,
    averageDayAmount: 498,
    planRatio: 100,
    ontimeAmount: 181611,
    ontimeRatio: 99.99,
    delayAmount: {
      total: 15,
      cheliang: 0,
      gongwu: 0,
      gongdian: 0,
      xinhao: 9,
      pingbimen: 1,
      diaodu: 0,
      chengwu: 1,
      zhanwu: 0,
      chengke: 1,
      otherEquipment: 0,
      other: 3
    },
    delayEvent: { m5: 5, m15: 0, m30: 0 },
    reliability: { m5: 841.7, m15: 0, m30: 0 },
    speed: { up: 35.14, down: 35.05 },
    tripTime: { up: 71.17, down: 71.33 },
    totalTime: 150,
    cleanPassenger: 4,
    cleanPassengerFrequency: 1052.12,
    rescueAmount: 0
  },

  {
    line: "3号线",
    year: "2017",
    runDays: 365,
    runMil: 256.46,
    maxInterval: 210,
    normalInterval: 320,
    planAmount: 131510,
    actualAmount: 131534,
    extraAmount: 24,
    averageDayAmount: 360,
    planRatio: 100,
    ontimeAmount: 131526,
    ontimeRatio: 99.99,
    delayAmount: {
      total: 8,
      cheliang: 0,
      gongwu: 2,
      gongdian: 0,
      xinhao: 2,
      pingbimen: 0,
      diaodu: 0,
      chengwu: 0,
      zhanwu: 0,
      chengke: 4,
      otherEquipment: 0,
      other: 0
    },
    delayEvent: { m5: 4, m15: 0, m30: 0 },
    reliability: { m5: 384.69, m15: 0, m30: 0 },
    speed: { up: 34.4, down: 34.2 },
    tripTime: { up: 34.05, down: 34.25 },
    totalTime: 75.72,
    cleanPassenger: 0,
    cleanPassengerFrequency: 0,
    rescueAmount: 0
  },

  {
    line: "4号线",
    year: "2017",
    runDays: 365,
    runMil: 488.78,
    maxInterval: 190,
    normalInterval: 3020,
    planAmount: 153768,
    actualAmount: 153794,
    extraAmount: 26,
    averageDayAmount: 421,
    planRatio: 100,
    ontimeAmount: 153788,
    ontimeRatio: 100,
    delayAmount: {
      total: 6,
      cheliang: 3,
      gongwu: 1,
      gongdian: 0,
      xinhao: 0,
      pingbimen: 0,
      diaodu: 0,
      chengwu: 0,
      zhanwu: 0,
      chengke: 1,
      otherEquipment: 0,
      other: 1
    },
    delayEvent: { m5: 2, m15: 0, m30: 0 },
    reliability: { m5: 1466.34, m15: 0, m30: 0 },
    speed: { up: 36.87, down: 36.71 },
    tripTime: { up: 68.85, down: 69.15 },
    totalTime: 145,
    cleanPassenger: 1,
    cleanPassengerFrequency: 2932.68,
    rescueAmount: 0
  },

  {
    line: "2号线",
    year: "2018",
    runDays: 365,
    runMil: 687.99,
    maxInterval: 160,
    normalInterval: 300,
    planAmount: 176261,
    actualAmount: 176325,
    extraAmount: 64,
    averageDayAmount: 483,
    planRatio: 100,
    ontimeAmount: 176298,
    ontimeRatio: 99.98,
    delayAmount: {
      total: 27,
      cheliang: 5,
      gongwu: 1,
      gongdian: 0,
      xinhao: 16,
      pingbimen: 3,
      diaodu: 0,
      chengwu: 1,
      zhanwu: 0,
      chengke: 0,
      otherEquipment: 0,
      other: 2
    },
    delayEvent: { m5: 6, m15: 1, m30: 0 },
    reliability: { m5: 687.99, m15: 4127.92, m30: 0 },
    speed: { up: 35.1, down: 35.1 },
    tripTime: { up: 71.25, down: 71.25 },
    totalTime: 150,
    cleanPassenger: 2,
    cleanPassengerFrequency: 2063.96,
    rescueAmount: 0
  },

  {
    line: "3号线",
    year: "2018",
    runDays: 365,
    runMil: 281.21,
    maxInterval: 190,
    normalInterval: 320,
    planAmount: 140517,
    actualAmount: 140550,
    extraAmount: 33,
    averageDayAmount: 385,
    planRatio: 100,
    ontimeAmount: 140536,
    ontimeRatio: 99.99,
    delayAmount: {
      total: 14,
      cheliang: 0,
      gongwu: 0,
      gongdian: 0,
      xinhao: 10,
      pingbimen: 2,
      diaodu: 0,
      chengwu: 0,
      zhanwu: 0,
      chengke: 0,
      otherEquipment: 0,
      other: 2
    },
    delayEvent: { m5: 5, m15: 0, m30: 0 },
    reliability: { m5: 337.46, m15: 0, m30: 0 },
    speed: { up: 36.41, down: 36.41 },
    tripTime: { up: 81.79, down: 81.79 },
    totalTime: 170.92,
    cleanPassenger: 0,
    cleanPassengerFrequency: 0,
    rescueAmount: 0
  },

  {
    line: "4号线",
    year: "2018",
    runDays: 365,
    runMil: 605.57,
    maxInterval: 190,
    normalInterval: 320,
    planAmount: 156158,
    actualAmount: 156281,
    extraAmount: 123,
    averageDayAmount: 428,
    planRatio: 100,
    ontimeAmount: 156278,
    ontimeRatio: 100,
    delayAmount: {
      total: 3,
      cheliang: 2,
      gongwu: 0,
      gongdian: 0,
      xinhao: 1,
      pingbimen: 0,
      diaodu: 0,
      chengwu: 0,
      zhanwu: 0,
      chengke: 0,
      otherEquipment: 0,
      other: 0
    },
    delayEvent: { m5: 1, m15: 0, m30: 0 },
    reliability: { m5: 3633.44, m15: 0, m30: 0 },
    speed: { up: 36.79, down: 36.79 },
    tripTime: { up: 69, down: 69 },
    totalTime: 145,
    cleanPassenger: 0,
    cleanPassengerFrequency: 0,
    rescueAmount: 0
  }
];*/

export default {
  name: "indexData",
  components: {
    chartLineAmount,
    chartTemp2,
    chartTemp3,
    chartDelay,
    chartClear,
    chartOntime,
    chartPassengerFlow
  },

  data() {
    return {
      totalData: [],
      choosedAmountData: "actualAmount",
      vs: "t",
      choosedProData: "",
      update_count: 1,
      curIcon: process.env.BASE_URL + "img/icon7.png",
      major: {
      cheliang: '车辆',
      gongwu: '工务',
      gongdian: '供电',
      xinhao: '信号',
      pingbimen: '站台门',
      diaodu: '调度',
      chengwu: '乘务',
      zhanwu: '站务',
      chengke: '乘客',
      otherEquipment: '设备',
      other: '其他'
    },
    };
  },
  created() {
            this.get_data();
        },
  methods: {
    link: function(value) {
      location.href = value;
    },
    chooseTrainClass(value) {
      this.choosedAmountData = value;
    },
    chooseProClass(value) {
      if (this.choosedProData === value) {
        this.vs = "t";
        this.choosedProData = "";
      } else {
        this.vs = "o";
        this.choosedProData = value;
      }
    },
    get_data() {
      this.$axios
        .post(this.$url + "/ask_base_data", {
          userToken: userToken
        })
        .then(
          response => {
            if(response.data.stat==='ok'){
              this.totalData = [];
              for(let ele of response.data.res_record){
                if(ele.line_code!=='net'){
                  this.totalData.push(ele);
                }
              }
            this.update_count++;
            }else{
              console.log('fali');
            }
        }
        );
    }
  }
};
</script>
<style scoped>
body,
ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
table,
td,
img,
div,
dl,
dt,
dd,
input {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Microsoft YaHei";
  font-size: 12px;
}

.outer-wrapper {
  overflow: hidden;
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  padding-bottom: 20px;
}

.title-box {
  outline: 2px dashed #999;
  outline-offset: 5px;
  padding: 0 30px;
}

.section-title {
  text-align: center;
  font-size: 22px;
  font-weight: 800;
  color: rgb(0, 0, 0, 0.9);
}
.data-wrapper {
  padding: 0;
}
.section-wrapper {
  padding: 50px 0;
}
.content-wrapper {
  display: flex;
}
.chart-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.d-ch {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin: 50px auto 0;
}

/*六方形*/
.con {
  height: 500px;
  width: 400px;
  padding: 0 50px;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.d {
  text-align: center;
  width: 300px;
  height: 80px;
}
.d:hover {
  transform: scale(1.1);
}

.img1 {
  background: url("~@/assets/home_0.png") no-repeat center;
  background-size: 100%;
}

.img2 {
  background: url("~@/assets/home_1.png") no-repeat center;
  background-size: 100%;
}

.img3 {
  background: url("~@/assets/home_2.png") no-repeat center;
  background-size: 100%;
}

.img4 {
  background: url("~@/assets/home_3.png") no-repeat center;
  background-size: 100%;
}

.img5 {
  background: url("~@/assets/home_4.png") no-repeat center;
  background-size: 100%;
}

.imgA {
  background: url("~@/assets/homeA.png") no-repeat center;
  background-size: 100%;
}
.imgB {
  background: url("~@/assets/homeB.png") no-repeat center;
  background-size: 100%;
}
.imgC {
  background: url("~@/assets/homeC.png") no-repeat center;
  background-size: 100%;
}
.imgD {
  background: url("~@/assets/homeD.png") no-repeat center;
  background-size: 100%;
}
.imgE {
  background: url("~@/assets/homeE.png") no-repeat center;
  background-size: 100%;
}
.imgF {
  background: url("~@/assets/homeF.png") no-repeat center;
  background-size: 100%;
}
.imgG {
  background: url("~@/assets/homeG.png") no-repeat center;
  background-size: 100%;
}
.imgH {
  background: url("~@/assets/homeH.png") no-repeat center;
  background-size: 100%;
}
.imgI {
  background: url("~@/assets/homeI.png") no-repeat center;
  background-size: 100%;
}
.imgJ {
  background: url("~@/assets/homeJ.png") no-repeat center;
  background-size: 100%;
}

.con .d p {
  font-size: 16px;
  line-height: 40px;
  color: #fff;
  text-align: center;
}

.delay-select {
  position: relative;
  height: 350px;
  width: 600px;
  margin: 100px 30px;
}
.delay-img {
  position: absolute;
  height: 100px;
  width: 100px;
}
.iA {
  top: 100px;
  left: 0;
}
.iB {
  top: 51px;
  left: 80px;
}
.iC {
  top: 2px;
  left: 160px;
}
.iD {
  top: 99px;
  left: 160px;
}
.iE {
  top: 143px;
  left: 80px;
}
.iF {
  top: 51px;
  left: 240px;
}
.iG {
  top: 143px;
  left: 240px;
}
.iH {
  top: 191px;
  left: 160px;
}
.iI {
  top: 192px;
  left: 0px;
}
.iJ {
  top: 235px;
  left: 80px;
}

.delay-img p {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  line-height: 65px;
}
.delay-img:hover > a > p {
  color: #993333;
  font-size: 20px;
}
.one-over {
  height: 660px;
  position: relative;
  margin: 50px 30px;

  width: 100%;
}
.rec1 {
  position: absolute;
  background: #fff;
  width: 430px;
  height: 430px;
  top: 0;
  left: 0;
}
.rec2 {
  position: absolute;
  background: #f7f5f5;
  width: 210px;
  height: 210px;
  left: 445px;
  top: 0;
  background: url("~@/assets/bg2.jpg") no-repeat center;
  background-size: 100%;
}
.t4img {
  width: 210px;
  height: 210px;
  margin: 0 10px;
  background: url("~@/assets/bg2.jpg") no-repeat center;
  background-size: 100%;
}
.rec3 {
  position: absolute;
  background: #f7f5f5;
  width: 343px;
  height: 210px;
  left: 670px;
  top: 0;
}
.rec4 {
  position: absolute;
  background: #f7f5f5;
  width: 430px;
  height: 430px;
  left: 445px;
  top: 225px;
}
.rec5 {
  position: absolute;
  background: #f7f5f5;
  width: 340px;
  height: 208px;
  left: 890px;
  top: 225px;
  background: url("~@/assets/bg3.jpg") no-repeat center;
  background-size: 100%;
}

.t5img {
  width: 340px;
  height: 208px;
  margin: 0 10px;
  background: url("~@/assets/bg3.jpg") no-repeat center;
  background-size: 100%;
}
.t6img {
  width: 210px;
  height: 210px;
  margin: 0 10px;
  background: url("~@/assets/bg4.gif") no-repeat center;
  background-size: 100%;
}
.t7img {
  width: 210px;
  height: 210px;
  margin: 0 10px;
  background: url("~@/assets/bg5.gif") no-repeat center;
  background-size: 100%;
}
.rec6 {
  position: absolute;
  background: #f7f5f5;
  width: 210px;
  height: 210px;
  left: 220px;
  top: 445px;
  background: url("~@/assets/bg4.gif") no-repeat center;
  background-size: 180%;
}
.rt {
  border-radius: 5px;
}
.rp {
  color: #993333 !important;
  font-weight: 700;
}
</style>